// Components
import CardContainer from '@/components/ui/CardContainer'
import ManagedUsersForm from '@/components/elements/users/ManagedUsersForm'
import ManagedUsersListItem from '@/components/elements/users/ManagedUsersListItem'
import VuetifyDataTable from '@/components/ui/VuetifyDataTable'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Services
import { getEveryUsersByIds } from '@/services/user'

export default {
  name: 'ManagedUsersList',
  components: {
    CardContainer,
    ManagedUsersListItem,
    VuetifyDataTable,
    VuetifyToolBar
  },
  mixins: [uiMixin],
  data() {
    return {
      // Table
      headers: [
        {
          text: 'Nombre',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Rol',
          value: 'role'
        },
        {
          text: 'Creado',
          value: 'createTimestamp'
        },
        { text: 'Acciones', align: 'center' }
      ],
      // Others
      items: [],
      processingRequest: true
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData']),
    ...mapState('app', ['extraSmallDevice']),
    /**
     * La "toolBar" es sticky
     *
     * @return {Boolean}
     */
    toolBarIsSticky() {
      return this.extraSmallDevice
    }
  },
  watch: {
    companyData: {
      handler(value) {
        if (value.users) {
          // Actualizamos la lista de empleados
          this.setItems()
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     *  Se abre un modal con el formulario de creación de usuario
     */
    handleClickToolBarActionButton() {
      this.modifyAppDialog({
        title: 'Crear nuevo usuario',
        contentComponent: ManagedUsersForm,
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Obtenemos los usuarios de la compañía
     */
    async setItems() {
      // Loading
      this.processingRequest = true
      try {
        // Filtramos usuario con el que estamos logado
        const userKeys = Object.keys(this.companyData.users).filter(
          (item) => item != this.userData.id
        )
        // Obtenemos datos de los usuarios
        this.items = await getEveryUsersByIds(userKeys)
      } catch (error) {
        // show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    }
  }
}
