// Constants
import { ROLES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'ManagedUsersForm',
  components: { FormButtons },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        name: null,
        email: null,
        password: null,
        role: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        password: {
          required: 'Campo obligatorio'
        },
        role: {
          required: 'Campo obligatorio'
        }
      },
      // Others
      togglePassword: false // to show or hide password field
    }
  },
  computed: {
    ...mapGetters('company', ['companyData']),
    /**
     * Devuelve la lista de roles formateada para v-select
     *
     * @return {Array}
     */
    availableRoles() {
      return Object.values(ROLES)
        .filter((role) => {
          return role.value === ROLES.brand_admin.value
        })
        .map((role) => {
          return {
            label: role.label,
            value: role.value
          }
        })
    }
  },
  methods: {
    ...mapActions('company', ['addUserToCompany']),
    /**
     * Avento de formulario
     */
    async afterSubmit() {
      // Se llama a la api de crear nuevo empleado
      await this.addUserToCompany({
        brand: getEnvironmentVariable('VUE_APP_BRAND'),
        companies: { [this.companyData.id]: true },
        ...this.formFields
      })

      // Show info
      this.modifyAppAlert({
        text: 'Usuario creado correctamente.',
        visible: true
      })

      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    }
  },
  validations: {
    formFields: {
      name: { required },
      email: { required, email },
      password: { required },
      role: { required }
    }
  }
}
