// Constants
import { ROLES } from '@/constants'
// Filters
import { formatDate } from '@/filters'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapActions } from 'vuex'

export default {
  name: 'ManagedUsersListItem',
  mixins: [uiMixin],
  props: {
    items: {
      required: true,
      type: Array,
      default() {
        return []
      }
    },
    loading: {
      required: true,
      type: Boolean,
      default: true
    }
  },
  filters: {
    /**
     * Obtenemos el rol del usuario (etiqueta)
     *
     * @param {string} id - id del rol
     * @return {id}
     */
    labelRoleById(id) {
      return ROLES[id] ? ROLES[id].label : '-'
    },
    formatDate
  },
  methods: {
    ...mapActions('company', ['deleteUserFromCompany']),
    /**
     * Evento que se lanza cuando pulsamos sobre
     * el botón de borrado
     *
     * @param {Object} item - datos del item que se desea borrar
     */
    async handleClickButton(item) {
      this.modifyAppAlert({
        actionButtonFn: async () => {
          try {
            this.processingRequest = true
            // Se elimina un usuario y se desasocia de company
            await this.deleteUserFromCompany(item.id)
          } catch (error) {
            // show error
            this.handleError(error.message)
          } finally {
            this.processingRequest = false
          }
        },
        actionButtonText: 'Borrar',
        text: '¿Estas seguro que quieres eliminar al usuario?',
        type: 'warning',
        visible: true
      })
    }
  }
}
